const Utils = require('../utils');
const DivSlot = require('../divSlot');
const AdserverBase = require('../adserverBase');

class AdformSlot extends DivSlot {}

const withAdform = ((fn) => {
	window.adformtag = window.adformtag || [];
	window.adformtag.push(() => fn(window.adformtag));
});

class AdformAdserver extends AdserverBase {
	init(__, doneCb) {
		withAdform((adformtag) => {
			this.adformtag = adformtag;
			doneCb();
		});
	}

	adUnitPathsFromUnitInternal({ adformMids }) {
		return adformMids;
	}

	getType() {
		return 'adform';
	}

	updateAdUnitPath(dstAdUnit, adUnitPath) {
		dstAdUnit.adformMids = [adUnitPath];
		return true;
	}

	getSlots() {
		return AdformSlot.list();
	}

	createSlot({ divId, path }) {
		return AdformSlot.getOrCreateSlot(divId, path);
	}

	sendAdserverRequest({ requestAuction, usedUnitDatas }) {
		const { adformtag } = this;
		const glob = AdformAdserver;

		const fns = {
			getHighestBid: ({ unitData }) => unitData.getHighestBid(),
			setAdformPbjsTargeting: ({ mid, bid }) => {
				adformtag.setRequestParam('fd', 1);
				// adformtag.setRequestParam('stid', bid.auctionId);
				adformtag.setPrice(mid, bid.cpm);
				adformtag.setCustomData(mid, 'hb_adid', bid.adId);
				adformtag.setTargeting(mid, 'hb_bidder', bid.bidder);
				adformtag.setTargeting(mid, 'hb_dealid', bid.dealId);
				adformtag.setTargeting(mid, 'hb_size', bid.size);
				if (bid.gdpr && !glob.gdprSet) {
					glob.gdprSet = true;
					adformtag.setRequestParam('gdpr', bid.gdpr);
					adformtag.setRequestParam('gdpr_consent', bid.gdpr_consent);
				}
			},
			setAdFormGlobalTargeting: ({ mid, globalTargeting }) => {
				Utils.entries(globalTargeting).forEach(([key, value]) => {
					adformtag.setTargeting(mid, key, value);
				});
			},
			makeAdformAdRequest: ({ elm, mid }) => {
				const scr = document.createElement('script');
				scr.setAttribute('data-adfscript', `adx.adform.net/adx/?mid=${mid}`);
				elm.innerHTML = '';
				elm.appendChild(scr);
				window.relevantDigital.registerRenderedDivId(elm.id);
			},
		};

		for (const fnName in fns) {
			const overrideFn = requestAuction[fnName];
			if (overrideFn) {
				const fn = fns[fnName];
				fns[fnName] = (p, ...rest) => overrideFn({ ...p, fn: () => fn(p, ...rest) });
			}
		}

		usedUnitDatas.forEach((unitData) => {
			const { code, slot } = unitData;
			const elm = document.getElementById(slot.getSlotElementId());
			const mid = slot.getAdUnitPath();
			if (!elm) {
				return;
			}
			const bid = fns.getHighestBid({ unitData, code });
			const globalTargeting = this.getGlobalTargeting();

			if (globalTargeting || bid) {
				if (glob.midTargetingsSet[mid]) {
					adformtag.resetTargeting(mid); // Targeting can't be updated in Adform, so it must be reset
				}
				glob.midTargetingsSet[mid] = true;
				fns.setAdFormGlobalTargeting({ mid, globalTargeting });
				if (bid) {
					fns.setAdformPbjsTargeting({ unitData, mid, bid });
				}
			}

			fns.makeAdformAdRequest({ unitData, elm, mid });
		});
	}
}

Utils.assign(AdformAdserver, {
	midTargetingsSet: {},
	gdprSet: false,
});

module.exports = AdformAdserver;
